
jQuery(document).ready(function() {    
    SearchboxSolr.init();
    jQuery('form#search_simple').attr('autocomplete', 'off');
    jQuery('div#search form').attr('autocomplete', 'off');
    jQuery('form#simplesearchfrm').attr('autocomplete', 'off');
    
    var searchbox_height = jQuery('div#search form fieldset input#search_trefwoord').outerHeight();
    jQuery('div#search form div.suggestions').css('top',searchbox_height);
    
    var module_searchbox_height = jQuery('form#simplesearchfrm fieldset input#trefwoord').outerHeight();
    jQuery('form#simplesearchfrm div.suggestions').css('top',module_searchbox_height);
    
    var module_searchbox_height_wr = jQuery('form#search_simple fieldset input#trefwoord').outerHeight();
    jQuery('form#search_simple div.suggestions').css('top',module_searchbox_height_wr);
});

SearchboxSolr = {
    suggestionDelay: 300, // delay (in msec) to wait before fetching the suggestions
    timeout: null,
    box: null,

    init: function() {
    	jQuery('input#trefwoord, input#search_trefwoord').keyup(function(event) {
            if (SearchboxSolr.timeout != null) {
                window.clearTimeout(SearchboxSolr.timeout);
            }
            SearchboxSolr.box = event.target;
            SearchboxSolr.timeout = window.setTimeout(SearchboxSolr.get_suggestions, SearchboxSolr.suggestionDelay);
        });

    	jQuery('input#trefwoord, input#search_trefwoord').parents('form').submit(function() {
           jQuery(this).find('input[name=domainroot]').replaceWith('');
        });

        var searchbox = jQuery('input[name=trefwoord]');
        var currentvalue = searchbox.val();
        searchbox.focus(
            function() {
                if(this.value != '' && jQuery(this).hasClass('searchbox')) {
                    this.value = '';
                }
            }
            );

        searchbox.blur(
            function() {
                if(this.value == '') {
                    this.value = currentvalue;
                }
            }
            );
    },

    get_suggestions: function() {
        var inputElement = SearchboxSolr.box;
        var inputValue = jQuery(inputElement).val();
        var words = inputValue.split(' ');

        var form = jQuery(inputElement).parents('form');
        var suggestionsUl = form.find('div.suggestions');
        var pagId = form.find('input[name=pagid]').val();
        var mediumId = form.find('input[name=mediumid]').val();

        var domainroot = form.find('input[name=domainroot]').val();
        var url = search_suggestion_url;
        if (domainroot != null) {
            url = domainroot + '/' + url;
        }

        // $.ajax({
            // url: url,
            // type: "POST",
            // data: {solr_trefwoord : words[words.length-1]},
            // dataType: "json",
            // success: function(data) {
                // suggestionsUl.html('');

                // var wordsLength = words.length;

                // if (data.suggestions.length > 0) {
                    // var lastWordSuggestion = data.suggestions[data.suggestions.length-1];
                    // var suggestionsUlList = jQuery(suggestionsUl).append('<ul></ul>').find('ul');
                    // jQuery(lastWordSuggestion.suggestions).each(function(index, item) {
                        // words[wordsLength-1] = item;
                        // var fullsuggestion = words.join(' ');

                        // suggestionsUlList.append(
                            // jQuery('<li></li>').append(
                                // jQuery('<a></a>').attr('href', '#').text(fullsuggestion).click(function(event) {
                                    // jQuery(inputElement).val(fullsuggestion);
                                    // form.find('#submit').trigger('click');
                                    // form.trigger('submit');
                                    // event.preventDefault();
                                // })
                            // )
                        // );
                    // });
                    // suggestionsUl.show('fast');
                // }
            // }
        // });
    }
};